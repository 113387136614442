import React from 'react'
import styled, { css, keyframes } from 'styled-components'

const COUNT = 150
const MAX_WIDTH = 24
const MIN_DURATION = 2
const DELTA_DURATION = 4
const ITERATIONS = 1
const DELAY = 0
const COLORS = ['#20F7FA', '#7777E5', '#FF3FD8']

const random = (limit?: number): number => {
  if (limit == null) {
    return Math.random()
  }
  return Math.round(Math.random() * (limit - 1) + 1)
}

const Outer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
`

const Particle = styled.div<{ index: number }>`
  ${({ index }) => {
    const w = random(MAX_WIDTH)
    const l = random(100)

    const drop = keyframes`
      100% {
        top: 110%;
        left: ${l + random(15)}%;
      }
    `

    return css`
      position: absolute;
      top: -10%;
      width: ${w}px;
      height: ${w * 0.4}px;
      background-color: ${COLORS[index % COLORS.length]};
      left: ${l}%;
      opacity: ${random() + 0.5};
      transform: rotate(${random(360)}deg);
      animation: ${drop} ${MIN_DURATION + random(DELTA_DURATION)}s
        ${DELAY + random()}s ${ITERATIONS};
    `
  }}
`

const renderParticles = (count: number) => {
  const particles = []

  while (count) {
    particles.push(<Particle index={--count} key={count} />)
  }

  return particles
}

interface Props {
  count?: number
}

const Confetti = ({ count = COUNT }: Props) => (
  <Outer>{renderParticles(count)}</Outer>
)

export default Confetti
