import { navigate } from 'gatsby'
import React, { useCallback } from 'react'
import styled, { css } from 'styled-components'

import Confetti from '../../../components/Confetti'
import {
  ContinueButton,
  Outer,
  Spacer,
} from '../../../components/join-forms/styled'
import Page from '../../../components/layout/Page'
import { mobile } from '../../../components/mixins'
import { Hero } from '../../../components/modules'
import mailNoMoney from '../../../components/refer/illustrations/mail-no-money.png'
import { Image } from '../../../components/refer/styled'

const StyledOuter = styled(Outer)`
  margin-top: 56px;
  padding-bottom: 120px;

  ${mobile(css`
    & > div:first-of-type {
      margin: 0 8px;
    }
  `)}

  button {
    width: 100%;
    max-width: 372px;
    margin: 0 24px;
  }
`

const SessionRegistrationComplete = () => {
  const startSurveyClicked = useCallback(() => {
    Promise.resolve(navigate('/form/sign-up')).catch(console.error)
  }, [])

  return (
    <Page
      head={{
        title: 'Thank you!',
      }}
    >
      <Confetti />
      <StyledOuter>
        <Image src={mailNoMoney} />
        <Hero
          title={'Thank you!'}
          text={{
            text: 'You’re officially registered for an upcoming call. We’re looking forward to meeting you and seeing how Double can support your business.\n\nWant to get help even faster? Complete a matching survey and we’ll start looking for your Double right away.',
          }}
        />
        <Spacer />
        <ContinueButton
          title={'Start survey'}
          isValid
          onClick={startSurveyClicked}
        />
      </StyledOuter>
    </Page>
  )
}

export default SessionRegistrationComplete
